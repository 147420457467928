import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import VCalendar from 'v-calendar'
import Moment from 'moment'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'
import localizedDate from '../filters/localized_date'
# STATE
import initialState from '../states/user-calculator'
# METHODS
import resetState from '../methods/user-calculator/reset_state'
import add_to_cart from '../methods/user-calculator/add_to_cart'


document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById  'tour-calculator'
  return false unless el

  Vue.use TurbolinksAdapter
  Vue.use VCalendar

  {title, tourUrl, image} = el.dataset

  new Vue {
    el
    filters: {toCurrency, localizedDate}
    data: -> initialState(title, tourUrl, image)
    methods: {add_to_cart, resetState}
    watch:
      selected_date: (value) ->
        return false unless value

        query = AxiosRequest(window.location.pathname + '/check_date', 'post', {date: Moment(value).format()})
        query.then ({data}) =>
          the_tour_date = data.base
          the_tour_date['worth_wd'] = data.base_worth
          @tour_date = the_tour_date
          @worths = data.worths
          @applied_discount = data.discount
    computed:
      worth_applied: ->
        final_worth = undefined
        for worth in @worths
          final_worth = worth.worth_wd if @persons >= worth.from_bookings
        final_worth = @tour_date.worth_wd unless final_worth
        final_worth
      worth_raw: ->
        final_worth = undefined
        for worth in @worths
          final_worth = worth.worth if @persons >= worth.from_bookings
          final_worth = @tour_date.worth unless final_worth
        final_worth

    mounted: ->
      query = AxiosRequest window.location.pathname + '/dates'
      query.then ({data}) =>
        if data.length > 0
          @available_dates = data[0].dates
        else
          @available_dates = [new Date '1990/01/01']
  }
