import SecureLS from 'secure-ls';
import Moment from 'moment'
import AxiosRequest from "../../utils/axios-request"

const locale = {
  es: {
    cart: 'Ir al carrito',
    tours: 'Ver mas tours de aventura',
    qparche_tours: 'Ver mas tours de ciudad'
  },
  en: {
    cart: 'Go to cart',
    tours: 'See more adventure tours',
    qparche_tours: 'See more city tours'
  }
};

export default function (lang, uid = 'joker') {
  const {
    cart,
    tours,
    qparche_tours
  } = lang === 'es' ? locale.es : locale.en;
  const find_data = {
    bookings: this.persons,
    comment: this.comment,
    date: Moment(this.selected_date).format(),
    tour_date_id: this.tour_date.idtour_date,
    tour_name: this.tour_name,
    tour_image: this.tour_image,
    tour_url: this.tour_url,
    worth: this.worth_applied,
    worth_before: this.worth_raw,
    discount: this.applied_discount
  };

  this.loading = true;

  const query = AxiosRequest('/customers/shopping_carts/add', 'post', {
    tour_date_id: this.tour_date.idtour_date,
    bookings: this.persons,
    date: Moment(this.selected_date).format()
  });

  query.then(({
                data
              }) => {
    this.loading = false;
    const ls = new SecureLS();
    let actual_cart = ls.get('cart');

    if (!actual_cart) actual_cart = {};
    if (!actual_cart[uid]) actual_cart[uid] = [];
    actual_cart[uid].push(find_data);
    ls.set('cart', actual_cart);
    this.selected_date = null;
    Swal.fire({
      title: data.message,
      icon: 'success',
      showConfirmButton: false,
      footer: `
        <div class="uk-flex uk-flex-wrap uk-flex-middle uk-flex-center uk-grid-small" uk-grid>
          <a class="uk-button magenta-button uk-margin-right" href="/customers/shopping_carts">${cart}</a>
          <a class="uk-button green-button" href="/tours">${tours}</a> <br>
          <a class="uk-button orange-button" href="/qparche/tours">${qparche_tours}</a>
        </div>
      `
    });
    this.resetState();
  });
}
