import initialState from '../../states/user-calculator';

export default function( preserveState = {} ) {
  const theOrigin = initialState();
  const { available_dates, tour_logo } = this;
  const theNewState = {
    ...theOrigin,
    ...preserveState,
    available_dates,
    tour_logo
  };
  Object.assign( this.$data, theNewState );
}
