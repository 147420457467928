export default function (title = '', tour_url, image) {
  return {
    selected_date: undefined,
    comment: '',
    persons: 1,
    available_dates: [new Date('1990/01/01')],
    tour_date: {},
    worths: [],
    tour_dates: [],
    applied_discount: 0,
    loading: false,
    tour_name: title,
    tour_image: image,
    tour_url: tour_url
  };
}