import axios from 'axios'

AxiosRequest = (url, method = 'get', params_data = {}) ->
  axios.defaults.headers.post['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content
  the_setup = {method, url}
  the_setup.data = params_data if method == 'post'
  the_setup.params = params_data if method == 'get'

  query = axios the_setup
  query.catch (error) ->
    try
      if error.response
        {response} = error
        Swal.fire {
          icon: 'error'
          title: response.data.message
          html: "
            <ul>
              <li class='uk-text-left'>#{ response.data.errors.join('</li><li>') }</li>
            </ul>
          "if response.data.errors
        }
      else if error.request
        console.log error.request
      else
        console.log 'Error', error.message
    catch error
      Swal.fire {
        icon: 'error'
        title: 'Unexpected error'
        text: 'Try again later.'
      }
  query

export default AxiosRequest
